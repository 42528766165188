<template>
  <Layout navbarAbsolute navbarTransparent> 
    <div style="" class="ql-editor bg-home">
      <div class="container d-flex" style="min-height:700px">
        <div class="mr-auto my-auto" style="max-width:auto;z-index:999;">
          <div class="mb-1" id="ABS-1">Lorem Ipsum Lorem Ipsum Lorem Ipsum</div> 
        </div>
      </div>
    </div>
    <img src="@/static/assets/wave.svg" style="margin-top:-15em;z-index:999;height:200px;object-fit:cover;object-position:bottom" class="w-100 position-relative" />
    <div class="container ql-editor pb-5">
        <div class="row align-items-center mb-5 pb-5">
            <div class="col-lg-5">
                <img src="@/static/assets/Group 705.png" class="w-100" />
            </div>
            <div class="col-lg-7">
                <div id="ABS-2" class="">
                    <div class="h1 text-center font-weight-bolder">About US</div>
                </div>
                <div class="mx-auto my-1" style="width:100px;height:5px;background:#6FA6D2;border-radius:5px"/>
                <div id="ABS-3" class="px-4">
                    
                </div>
            </div>
        </div>
    </div>

    <div class="ql-editor pb-3" style="background:#E8F1F8">
        <div class="container">
            <div class="my-5">
                <div id="ABS-4" class="">
                    <div class="h1 text-center font-weight-bolder">Why We Different</div>
                </div>
                <div class="mx-auto my-1" style="width:100px;height:5px;background:#6FA6D2;border-radius:5px"/>
                <div class="row mt-5">
                    <div class="col-lg-3 d-flex flex-column align-items-center">
                        <img src="@/static/about/Two in One.png" style="max-width:200px;height:200px;object-fit:contain" />
                        <div id="ABS-5" class="mt-3">
                        </div>
                    </div>
                    <div class="col-lg-3 d-flex flex-column align-items-center">
                        <img src="@/static/about/Pratical Experts.png" style="max-width:200px;height:200px;object-fit:contain"  />
                        <div id="ABS-6" class="mt-3">
                        </div>
                    </div>
                    <div class="col-lg-3 d-flex flex-column align-items-center">
                        <img src="@/static/about/Customizable Solutions.png" style="max-width:200px;height:200px;object-fit:contain" />
                        <div id="ABS-7" class="mt-3">
                        </div>
                    </div>
                    <div class="col-lg-3 d-flex flex-column align-items-center">
                        <img src="@/static/about/Based on Compassion.png" style="max-width:200px;height:200px;object-fit:contain" />
                        <div id="ABS-8" class="mt-3">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container ql-editor pb-5" id="CLIENTS">
        <div class="my-5 pb-3">
            <div id="ABS-9" class="">
                <div class="h1 text-center font-weight-bolder">Featured Clients</div>
            </div>
            <div class="mx-auto my-1" style="width:100px;height:5px;background:#6FA6D2;border-radius:5px"/>
            <div id="ABS-10" class="mt-3 mb-5"></div>

            <div class="d-flex flex-wrap justify-content-center my-3" style="gap:50px">
                <img style="height:80px" :src="item.client_image" v-for="item in clients" :key="item.client_id" />
            </div>
        </div> 

        <Servicetron>
            <div id="ABS-11" class="text-white">DON'T WAIT TO TRANSFORM! LET US HELP YOU</div>
                <router-link to="/contacts" style="">
                <div
                    class="mt-1 mx-auto p-1 px-2 rounded-pill shadow fab-help"
                    style="max-width: fit-content;"
                >
                    <span id="ABS-12">CONTACT US</span>
                </div>
            </router-link>
        </Servicetron>

    </div> 

  </Layout>
</template>

<script>
import Layout from "@/layouts/main.vue";
import Servicetron from "@/components/home/Servicetron.vue"; 
import store from '@/store'

export default {
  components: {
    Layout,  Servicetron
  },
  methods: {
    async getNextDatas(){
      let temp = await store.dispatch(`page_content/getDataList`, {
          lang_code: this.currentLocale,
          code: 'ABS',  
      }) 
      temp?.map((item, key) => {
        document.getElementById(item.code).innerHTML = item.content
      })
      this.$forceUpdate()
    },
    async getClients(){
      let temp = await store.dispatch(`clients/getDataList`) 
      this.clients = temp
      this.$forceUpdate()
    }, 
  },
  mounted(){
      this.getNextDatas()
      this.getClients() 
  },
  computed: {
    currentLocale() {
      return this.$store.state.locales.CURRENT
    },
  },
  watch: {
    currentLocale(val) {
      this.getNextDatas();
    },
  },
  data(){ 
    return {
      page_content : {

      },
      clients: [], 
    }
  }
};
</script>

<style>
.mask1 {
  -webkit-mask-image: linear-gradient(to top, transparent 0%, black 40%);
  mask-image: linear-gradient(to top, transparent 0%, black 90%);
}
.bg-d {
  border-radius: 20px;
  background-color: #0b5087;
  background-image: url('../static/assets/home/cscs 2.png');
} 
.bg-home {
  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 40%);
  mask-image: linear-gradient(to bottom, transparent 0%, black 0%);
  background-image:url('../static/assets/Group 704.png'); 
}
 
</style>